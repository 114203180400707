

const PageWrapper = ({ children }) => {
    return (
        <div className="bg-dusk pb-8 md:pb-16">
            {children}
        </div>
    );
};

export default PageWrapper;